header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;

}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* =================== CTA ======================= */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;


}

/* =================== HEADER SOCIALS ======================= */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

}

.header__socials::after {
    content: '';
    width: 1px;
    height: 3rem;
    background-color: var(--color-primary);
}

/* =================== ME ======================= */
.header__container .me{
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    border-radius: 12rem 12rem 0 0 ;
    overflow: hidden;
    padding: 5rem 1.5rem  1.5rem ;
    
}

.header__container .me img{
    border-radius:50% 50% 0 0;

}

/* =================== SCROLL DOWM ======================= */
.scroll__down{
    position: absolute;
    right:-2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
    font-weight: 300;

}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px){
   heder {
       height: 68vh;
   }
}
/* =================== MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 600px){
   header{
       height: 100vh;
   } 

   .header__socials, .scroll__down{
       display: none;
   }
}